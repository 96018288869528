import Loader from '../common/Loader';
import axios from 'axios';
import React, { createRef, useEffect, useState } from 'react';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

const InvoicesTable = () => {
  const [loading, setLoading] = useState(true)
  const [payments, setPayments] = useState(true)
  let table = createRef();
  const handleNextPage = ({ page, onPageChange }) => () => {
    onPageChange(page + 1);
  };

  const handlePrevPage = ({ page, onPageChange }) => () => {
    onPageChange(page - 1);
  };

  const downloadPdf = (id, month) => {
    axios({
      url: process.env.REACT_APP_API_ENDPOINT + '/api/invoice/' + id,
      responseType: 'blob',
      method: 'GET'
    }
    ).then(response => {
      console.log(response)
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${month}請求書.pdf`);
      document.body.appendChild(link);
      link.click();
    }).catch((error) => {
      console.log(error);
    });
  }

  const downloadCsv = (reading, month) => {
    axios({
      url: process.env.REACT_APP_API_ENDPOINT + '/api/csv/procurement?reading=' + reading,
      // responseType: 'blob',
      method: 'GET'
    }
    ).then(response => {
      console.log(response)
      const url = window.URL.createObjectURL(new Blob([response.data], {type: 'text/csv'}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${month}調達費用.csv`);
      document.body.appendChild(link);
      link.click();
    }).catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/payments').then(response => {
      console.log(response)
      setPayments(response.data)
      setLoading(false)
    }).catch((error) => {
      console.log(error.response);
    });
  }, []);

  const options = {
    custom: true,
    sizePerPage: 9,
    totalSize: payments.length
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => {
            const lastIndex = paginationProps.page * paginationProps.sizePerPage;
            return (
              <>
                <div className="table-responsive">
                  <>
                    <BootstrapTable
                      ref={table}
                      bootstrap4
                      keyField="id"
                      // data={[{
                      //   id: 1,
                      //   month: '2020年10月',
                      //   name: '事務所',
                      //   uuid: '401303644070200000000',
                      //   amount: "¥ 17,693",
                      //   kwh: "720",
                      //   pdf: <a href={process.env.REACT_APP_API_ENDPOINT + '/invoice/c42af001'} target="_blank" rel="noopener noreferrer">PDF</a>,
                      //   csv: <a href={process.env.REACT_APP_API_ENDPOINT + '/consignment/c42af001'} target="_blank" rel="noopener noreferrer">"CSV"</a>,
                      // }]}
                      data={payments.map(function(item) {
                        return Object.assign(
                          item,
                          {
                            pdf: <a onClick={() => downloadPdf(item.id, item.month)} href="javascript:void(0)">PDF</a>,
                            csv: <a onClick={() => downloadCsv(item.reading, item.month)} href="javascript:void(0)">CSV</a>,
                          }
                        )
                          // return item.concat({
                          //   pdf: <a href={process.env.REACT_APP_API_ENDPOINT + '/invoice/c42af001'} target="_blank" rel="noopener noreferrer">PDF</a>,
                          //   csv: <a href={process.env.REACT_APP_API_ENDPOINT + '/consignment/c42af001'} target="_blank" rel="noopener noreferrer">"CSV"</a>
                          //   }
                          // )
                      })}
                      columns={[
                        {
                          dataField: 'month',
                          text: '請求月',
                          classes: 'border-0 align-middle',
                          headerClasses: 'border-0',
                          sort: true,
                        },
                        {
                          dataField: 'contract_name',
                          text: '施設名',
                          classes: 'border-0 align-middle',
                          headerClasses: 'border-0',
                          sort: true,
                        },
                        {
                          dataField: 'contract_uuid',
                          text: '供給地点特定番号',
                          classes: 'border-0 align-middle',
                          headerClasses: 'border-0',
                          sort: true,
                        },
                        {
                          dataField: 'total',
                          text: '請求金額(税込)',
                          classes: 'border-0 align-middle',
                          headerClasses: 'border-0',
                          sort: true,
                        },
                        {
                          dataField: 'quantity',
                          text: '合計使用電力量(kWh)',
                          classes: 'border-0 align-middle',
                          headerClasses: 'border-0',
                          sort: true,
                        },
                        {
                          dataField: 'pdf',
                          text: '請求書PDF',
                          classes: 'border-0 align-middle',
                          headerClasses: 'border-0',
                        },
                        {
                          dataField: 'csv',
                          text: '調達費用CSV',
                          classes: 'border-0 align-middle',
                          headerClasses: 'border-0',
                        }
                      ]}
                      bordered={false}
                      classes="table-dashboard table-sm fs--1 border-bottom border-200 mb-10 table-dashboard-th-nowrap"
                      rowClasses="btn-reveal-trigger border-top border-200"
                      headerClasses="bg-200 text-900 border-y border-200"
                      {...paginationTableProps}
                    />
                    <Row noGutters className="px-1 py-3">
                      <Col className="pl-3 fs--1">
                        <span>
                          {(paginationProps.page - 1) * paginationProps.sizePerPage + 1} to {lastIndex > paginationProps.totalSize ? paginationProps.totalSize : lastIndex} of {paginationProps.totalSize}
                        </span>
                      </Col>
                      <Col xs="auto" className="pr-3">
                        <Button
                          color={paginationProps.page === 1 ? 'light' : 'primary'}
                          size="sm"
                          onClick={handlePrevPage(paginationProps)}
                          disabled={paginationProps.page === 1}
                          className="px-4"
                        >
                          前ページ
                        </Button>
                        <Button
                          color={lastIndex >= paginationProps.totalSize ? 'light' : 'primary'}
                          size="sm"
                          onClick={handleNextPage(paginationProps)}
                          disabled={lastIndex >= paginationProps.totalSize}
                          className="px-4 ml-2"
                        >
                          次ページ
                        </Button>
                      </Col>
                    </Row>
                  </>
                </div>
              </>
            );
          }}
        </PaginationProvider>
      )}
    </>
  );
};

export default InvoicesTable;
