import PropTypes from 'prop-types';
import { NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { breakpoints } from '../../helpers/utils';
import { topNavbarBreakpoint } from '../../config';
import { Button} from 'reactstrap';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const NavbarTopDropDownMenus = ({ setNavbarCollapsed }) => {
  const handleSetNavbarCollapsed = () => {
    const windowWidth = window.innerWidth;
    windowWidth < breakpoints[topNavbarBreakpoint] && setNavbarCollapsed(false);
  };
  const handleLogout = () => {
    collapseOneOpen(true)
  };
  
  const [collapseOne, collapseOneOpen] = useState(false);

  return (
    <>
      <NavItem onClick={handleSetNavbarCollapsed}>
        <NavLink className="nav-link ml-3" to="dashboard">
          トップページ
        </NavLink>
      </NavItem>
      <NavItem onClick={handleSetNavbarCollapsed}>
        <NavLink className="nav-link ml-3" to="profile">
          お客様情報
        </NavLink>
      </NavItem>
      <NavItem onClick={handleSetNavbarCollapsed}>
        <NavLink className="nav-link ml-3" to="contact">
          お問い合わせ
        </NavLink>
      </NavItem>
      <NavItem onClick={handleSetNavbarCollapsed}>
        <NavLink className="nav-link ml-3" to="clause">
          ご契約約款等
        </NavLink>
      </NavItem>
      <NavItem onClick={handleSetNavbarCollapsed}>
        <a className="nav-link ml-3" href="https://aichi-denryoku.jp" target="_blank" rel="noopener noreferrer">
          シーラソーラーホームページ
        </a>
      </NavItem>
      <NavItem onClick={handleLogout}>
        <Link className="nav-link ml-3" to="#">
          ログアウト
        </Link>
        <Modal isOpen={collapseOne} toggle={() => collapseOneOpen(!collapseOne)}>
          <ModalHeader>ログアウト確認</ModalHeader>
          <ModalBody>
            ログアウトしますか？
          </ModalBody>
          <ModalFooter>
            <Button tag={Link} to="/auth/logout" color="primary" onClick={() => collapseOneOpen(!collapseOne)}>
              ログアウト
            </Button>
            <Button onClick={() => collapseOneOpen(!collapseOne)}>
              キャンセル
            </Button>
          </ModalFooter>
        </Modal>
      </NavItem>
    </>
  );
};

NavbarTopDropDownMenus.propTypes = { setNavbarCollapsed: PropTypes.func.isRequired };

export default NavbarTopDropDownMenus;
